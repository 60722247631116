@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;600;900&display=swap');

.section {
  @apply w-5/6 mx-auto my-8 md:my-14 py-8;
}

h3 {
  @apply font-bold text-lg lg:text-2xl my-4;
}

h4 {
  @apply font-bold text-base lg:text-xl my-4;
}

.circle {
  @apply rounded-full bg-white h-16 w-16 mx-auto -mt-12;
}

body {
  font-family: 'Work Sans', sans-serif;
}

@layer base {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

.table-container .rounded-header th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.table-container .rounded-header th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
